import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useCollections = () => {

  // base data
  const dialog = ref(false);
  const collectionEdited = ref(null);
  const collections = ref([]);
  const search = ref("");
  const loading = ref(false);

  // computed properties
  const titleDialog = computed(() =>
    collectionEdited.value ? "Editar Collección" : "Agregar Collección"
  );
  const subtitleDialog = computed(() =>
    collectionEdited.value ?
      "Rellene los campos correctamente para modificar los datos del Collección" :
      "Rellene los campos correctamente para validar los datos del nuevo Collección"
  );

  const closeDialog = () => {
    dialog.value = false;
    collectionEdited.value = null;
  }

  // methods
  const getCollectionsList = async () => {
    const { data, status } = await store.dispatch("fetchCollections")
    // console.log(data)
    if (status != 200) return;
    collections.value = data;
  }

  const createCollection = async (collection) => {
    loading.value = true;
    const { data, status } = await store.dispatch("createCollection", collection)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getCollectionsList();
    closeDialog();
  }

  const updateCollection = async (collection) => {
    loading.value = true;
    const { data, status } = await store.dispatch("updateCollection", collection)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getCollectionsList();
    closeDialog();
  }

  const removeCollection = async (collection) => {
    const val = await store.dispatch("generateConfirmDialog", {});
    if (!val) return;
    const { data, status } = await store.dispatch("removeCollection", collection)
    if (status != 200 && status != 201 && status != 204) return;
    await getCollectionsList();
  }

  getCollectionsList();

  return {
    dialog,
    collectionEdited,
    collections,
    search,
    loading,
    // computed
    titleDialog,
    subtitleDialog,
    // methods
    changeSearch(ev) {
      search.value = ev
    },
    openDialog() {
      dialog.value = true;
    },
    openEdit(collection) {
      dialog.value = true;
      collectionEdited.value = { ...collection };
    },
    openRemove: removeCollection,
    closeDialog,
    createCollection,
    updateCollection
  };
}

export default useCollections;
