var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-rounded datatable-height",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.books,"loading":_vm.loading,"item-key":"name","fixed-header":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.img_book",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"icon-service center-sub-items"},[_c('img',{attrs:{"draggable":"false","src":_vm.loadImage(item.img_book),"alt":item.title}})])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.title))])])]}},{key:"item.buy_url",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('a',{attrs:{"href":item.buy_url}},[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(_vm._s(_vm.mdiCartVariant)+" ")])],1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('CrudButtons',{on:{"edit":function($event){return _vm.$emit('edit', item)},"remove":function($event){return _vm.$emit('remove', item)}}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }