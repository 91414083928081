<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="books"
      :loading="loading"
      item-key="name"
      class="table-rounded datatable-height"
      fixed-header
      disable-sort
    >
      <template #[`item.img_book`]="{ item }">
        <div class="icon-service center-sub-items">
          <img draggable="false" :src="loadImage(item.img_book)" :alt="item.title" />
        </div>
      </template>
      <template #[`item.title`]="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.title }}</span>
          <!-- <small>{{ item.post }}</small> -->
        </div>
      </template>
      <template #[`item.buy_url`]="{ item }">
        <div class="d-flex flex-column">
          <a :href="item.buy_url">
            <v-icon color="green darken-2">{{ mdiCartVariant }} </v-icon>
          </a>
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <CrudButtons @edit="$emit('edit', item)" @remove="$emit('remove', item)"></CrudButtons>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CrudButtons from '@/components/buttons/CrudButtons.vue'
import { loadAPIImage } from '@/utils/images'
import { mdiCartVariant } from '@mdi/js'

export default {
  components: {
    CrudButtons,
  },
  props: {
    books: Array,
    search: String,
    loading: Boolean,
  },
  setup() {
    const statusColor = {
      Activo: 'primary',
      Inactivo: 'error',
    }

    const loadImage = src => {
      return loadAPIImage(src)
    }

    return {
      loadImage,
      mdiCartVariant,
      headers: [
        { text: 'PORTADA LIBRO', value: 'img_book' },
        { text: 'LIBRO', value: 'title' },
        { text: 'COMPRA', value: 'buy_url' },
        { text: 'COLLECCIONES', value: 'collections' },
        { text: '', value: 'actions', align: 'right' },
      ],
      status: {
        1: 'Activo',
        2: 'Inactivo',
        //3: 'Rechazado',
      },
      statusColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  padding: 10px;
  margin: 10px;
}

.icon-service img {
  max-width: 150px;
  max-height: 150px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>