<template>
  <v-card>
    <BookHeader @openToAdd="openDialog" @search="changeSearch"></BookHeader>
    <DatatableBooks
      :books="books"
      :loading="loading"
      :search="search"
      @edit="openEdit"
      @remove="openRemove"
    ></DatatableBooks>
    <WDialog width="500" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormBook
          v-if="dialog"
          :loading="loading"
          :bookEdited="bookEdited"
          :collections="collections"
          @beforeUpdate="updateBook"
          @beforeCreate="createBook"
        ></FormBook>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import DatatableBooks from './DatatableBooks.vue'
import FormBook from '@/components/forms/FormBook.vue'
import BookHeader from './BooksHeader.vue'
import WDialog from '@/components/dialogs/WDialog.vue'
import useBooks from '@/composables/useBooks'
import useCollections from '@/composables/useCollections'

export default {
  components: {
    DatatableBooks,
    FormBook,
    BookHeader,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      bookEdited,
      books,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createBook,
      updateBook,
    } = useBooks()

    const { collections } = useCollections();

    return {
      dialog,
      books,
      search,
      loading,
      bookEdited,
      collections,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createBook,
      updateBook,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
